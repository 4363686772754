import React, { useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings'
import SettingDialog from './SettingDialog'

function Header({
    locationUpdated,
})
{
    const [ openSetting, setOpenSetting ] = useState(false)

    const openSettingDialog = () => {
        setOpenSetting(true)
    }

    const locationUpdateHandle = () => {
        setOpenSetting(false)
        locationUpdated()
    }

    return (
        <Grid container style={{
            backgroundColor: '#72ade0',
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Grid item xs={4}
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingLeft: '50px',
                }}
            >
                Header
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <IconButton
                    onClick={openSettingDialog}
                >
                    <SettingsIcon />
                </IconButton>
            </Grid>
            <SettingDialog 
                open={openSetting}
                handleClose={() => setOpenSetting(false)}
                locationUpdateHandle={locationUpdateHandle}
            />
        </Grid>
    )
}

export default Header