import React from 'react'
import { Grid, Button } from '@mui/material'

function LocationList({
    list,
    setLocation,
}) {
    const listItemOnClick = (item) => {
        setLocation(item.LocationName)
    }

    return (
        <Grid container spacing={1}
            style={{
                borderRadius: '5%',
                backgroundColor: '#30c7e378',
                padding: '10px',
                minHeight: '35vh',
                maxHeight: '35vh',
                overflowY: 'auto',
                width: '95%',
            }}
        >
            {list.map((location, index) => {
                return (
                    <Grid item xs={6} md={6} lg={12}
                        style={{
                            height: '100px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                        key={index}
                    >
                        <Button
                             onClick={() => listItemOnClick(location)}
                             style={{
                                width: '90%',
                                backgroundColor: '#00000033',
                                color: 'white',
                                fontSize: '12px',
                             }}
                        >
                            {location.StoreName}
                            <span style={{
                                fontSize: '10px',
                            }}>{location.locationName}</span>
                        </Button>
                        
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default LocationList