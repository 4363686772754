import React, { useState, useEffect } from 'react'
import './App.css'
import Header from './components/Header'
import LocationList from './components/LocationList'
import { Unity, useUnityContext } from 'react-unity-webgl'
import { Grid, Button } from '@mui/material'
import StoreInfo from './services/storeInfo'

function App() {
  const { isLoaded, loadingProgression , addEventListener, removeEventListener, sendMessage, unityProvider } = useUnityContext({
    loaderUrl: 'assets/MapFloor.loader.js',
    dataUrl: 'assets/MapFloor.data',
    frameworkUrl: 'assets/MapFloor.framework.js',
    codeUrl: 'assets/MapFloor.wasm',
  });

  const [ list, setList ] = useState([])
  const [ locationName, setLocationName ] = useState('')

  const sendCancelMessageToUnity = () => {
    sendMessage("Main Camera", "CancelSelection")
  }

  const setupLocationValue = (FloorName, LocationName, StoreName, StoreDescription) => {
    const message = {
      FloorName,
      LocationName,
      StoreName,
      StoreDescription,
    }
    sendMessage("MapView", "SetLocationInfo", JSON.stringify(message))
  }

  const getMessageFromUnity = (name) => {
    setLocationName(name)
  }

  const updatedLocation = () => {
    const data = StoreInfo.getCurrentStoreJson()
    setList(data)
    setupLocationToUnity()
  }

  const setupLocationToUnity = () => {
    const data = StoreInfo.getCurrentStoreJson()
    for (let i = 0; i < data.length; i++)
    {
      const location = data[i]
      setupLocationValue(location.FloorName, location.LocationName, location.StoreName, location.StoreDescription)
    }
  }

  const setStartingLocationToUnity = (index) => {
    sendMessage("StartPointControl", "SetStartPoint", index)
  }

  const selectLocationToUnity = (locationName) => {
    sendMessage("Main Camera", "SelectLocationByLocationInfo", locationName)
  }

  useEffect(() => {
    const data = StoreInfo.getCurrentStoreJson()
    setList(data)
  }, [])
  
  useEffect(() => {
    addEventListener("LocationMessage", getMessageFromUnity)
    addEventListener("SetUpLocation", setupLocationToUnity)
    return () => {
      removeEventListener("LocationMessage", getMessageFromUnity)
      removeEventListener("SetUpLocation", setupLocationToUnity)
    }
  }, [sendMessage, addEventListener, removeEventListener])

  return (
    <div className="App">
      <Grid container style={{
        backgroundColor: '#acccff',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}>
        <Header 
          locationUpdated={updatedLocation}
        />
        <Grid item xs={12} container>
          <Grid item xs={12} md={12} lg={8}>
            <Unity 
              style={{ 
                visibility: isLoaded ? "visible" : "hidden",
                minHeight: '40vh',
              }}
              className={'unity-window'} 
              unityProvider={unityProvider} 
            />
          </Grid>
          <Grid item xs={12} md={12} lg={4}
            style={{
              minHeight: '35vh',
              width: '80%',
              marginTop: '40px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid container>
              <Grid item xs={12} style={{ marginLeft: '30px' }}>
                <LocationList
                  setLocation={selectLocationToUnity}
                  list={list}
                />
              </Grid>
              <Grid item xs={12}>
                Set Starting Location:
                <Grid container style={{ marginTop: '10px', marginRight: '30px' }}>
                  <Grid item xs={4}>
                    <Button variant="contained" onClick={() => setStartingLocationToUnity(1)}>1</Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button variant="contained" onClick={() => setStartingLocationToUnity(2)}>2</Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button variant="contained" onClick={() => setStartingLocationToUnity(3)}>3</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
