import React, { useState, useEffect } from 'react'
import { 
    Dialog, 
    DialogTitle, 
    DialogContent,
    DialogActions,
    Grid, 
    TextField, 
    Button
} from '@mui/material'
import StoreInfo from '../services/storeInfo'

function SettingDialog({
    open,
    handleClose,
    locationUpdateHandle,
}) {

    const [ list, setList ] = useState([])

    useEffect(() => {
        if (open) {
            const data = StoreInfo.getCurrentStoreJson()
            setList(data)
        }
    }, [open])

    const onDataChange = (index, field, value) => {
        const updated = JSON.parse(JSON.stringify(list))
        updated[index][field] = value
        setList(updated)
    }

    const saveSetting = () => {
        StoreInfo.saveStoreRecord(list)
        locationUpdateHandle()
    }

    return (
        <Dialog onClose={handleClose} open={open} maxWidth={'lg'}>''
            <DialogTitle>Store Information</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {list.map((location, index) => {
                        return (
                            <Grid container item spacing={0} key={index}>
                                <Grid item xs={3}>{location.LocationName}</Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        value={location.StoreName}
                                        onChange={(e) => onDataChange(index, 'StoreName', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        value={location.StoreDescription}
                                        onChange={(e) => onDataChange(index, 'StoreDescription', e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        )
                    })}    
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={saveSetting}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SettingDialog