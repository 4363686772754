import StoreJson from '../data/store.json';

const getCurrentStoreJson = () => {
    const savedData = localStorage.getItem('location')
    if (savedData) {
        const output = JSON.parse(savedData)
        return output
    }
    return StoreJson
}

const saveStoreRecord = (recordJson) => {
    localStorage.setItem('location', JSON.stringify(recordJson))
}

const StoreInfo = {
    getCurrentStoreJson,
    saveStoreRecord,
}

export default StoreInfo